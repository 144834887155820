import { INewsCategory, INewsItem } from '../types';
import { contentType } from './content-type.mock';
import { getMockedNewsItems } from './news-item.mock';

export function getMockedNewsCategories(n?: number): INewsCategory[];
export function getMockedNewsCategories(
  n: number,
  options: { nested: false },
): INewsCategory[];
export function getMockedNewsCategories(
  n: number,
  options: { nested: true },
): INewsItem['attributes']['news_categories'];

export function getMockedNewsCategories(
  n = 3,
  options: { nested?: boolean } = {},
): INewsCategory[] | INewsItem['attributes']['news_categories'] {
  const { nested = false } = options;
  const newsCategories = [];

  do {
    const id = (newsCategories.length + 1) as number;
    const category = {
      id,
      attributes: {
        name: `Name of news category ${id}`,
        slug: `name-of-news-category-${id}`,
        ...contentType,
      },
    };

    if (!nested) {
      (category as INewsCategory).attributes.news_items = {
        data: getMockedNewsItems(),
      };
    }

    newsCategories.push(category);
  } while (newsCategories.length < n);

  return nested
    ? ({
        data: newsCategories,
      } as INewsItem['attributes']['news_categories'])
    : (newsCategories as INewsCategory[]);
}

export const getMockedNewsCategory = () => getMockedNewsCategories(1)[0];
