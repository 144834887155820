@if (data) {
  <div
    class="calls-to-action-section flex flex-wrap"
  [ngClass]="{
    'flex-row': (breakpointObserver.ltMd$ | async) === false,
    'flex-column': (breakpointObserver.ltMd$ | async) === true
  }"
    (mouseleave)="!isTouchDevice ? (hoveredItemIndex = undefined) : null"
    >
    @for (item of data.items; track item.id; let i = $index) {
      <a
        class="call-to-action flex flex-column justify-center items-center center relative"
        tuiMode="onDark"
        [href]="item.callToActionLink"
        target="_blank"
        (mouseenter)="!isTouchDevice ? (hoveredItemIndex = i) : null"
        >
        <ffb-row class="full-width">
          @if (hoveredItemIndex === i) {
            <cms-video-image-or-carousel
              *hide.ltMd="true"
              [media]="item"
              disposition="background"
              [controls]="false"
              [aspectRatio]="1 / 1"
            ></cms-video-image-or-carousel>
          }
          <div
            class="px2"
        [ngStyle]="{
          visibility: hoveredItemIndex === i ? 'hidden' : 'visible'
        }"
            >
            @if (item.title) {
              <h4 class="tui-text_h4 mb3">{{ item.title }}</h4>
            }
            @if (item.subtitle) {
              <p>{{ item.subtitle }}</p>
            }
          </div>
          @if (isTouchDevice || hoveredItemIndex === i) {
            <a
              [href]="item.callToActionLink"
              tuiButton
              icon="tuiIconArrowRight"
              appearance="calls-to-action-section-button"
              target="_blank"
        [ngStyle]="
          !isTouchDevice && {
            position: 'absolute',
            top: '50%',
            letf: '50%',
            transform: 'translate(-50%, -50%)'
          }
        "
        [ngClass]="
          (breakpointObserver.ltSm$ | async) === true
            ? {
                mt3: isTouchDevice,
                'max-content-width': !isTouchDevice,
                'full-width': isTouchDevice
              }
            : {
                'max-content-width': true,
                mt3: isTouchDevice
              }
        "
        >
        {{ item.callToActionLabel }}
      </a>
    }
  </ffb-row>
</a>
}
</div>
}
