import { TLocale } from '../types/locale.type';

export const contentType = {
  createdAt: new Date('2020-07-04').toISOString(),
  updatedAt: new Date('2021-03-19').toISOString(),
  publishedAt: new Date('2021-03-19').toISOString(),
};

export const localizedContentType = {
  ...contentType,
  locale: 'en' as TLocale,
  localizations: { data: [] },
};
