import { AsyncPipe, NgClass, NgStyle } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { ResizeObserverModule } from '@ng-web-apis/resize-observer';
import { TuiButtonModule, TuiLinkModule } from '@taiga-ui/core';
import { SwiperComponent, SwiperModule } from 'swiper/angular';
import { SwiperEvents } from 'swiper/types';
import {
  ContentAdornmentDirective,
  ImageComponent,
  LineClampComponent,
  RowComponent,
} from '@lancelot-frontend/components';
import { BreakpointObserver, ShowHideDirective } from '@lancelot-frontend/core';
import { IArticleCategory, IArticlesSection } from '../../types';

@Component({
  selector: 'cms-articles-section',
  templateUrl: './articles-section.component.html',
  styleUrls: ['./articles-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ContentAdornmentDirective,
    ResizeObserverModule,
    RowComponent,
    NgClass,
    TuiLinkModule,
    NgStyle,
    SwiperModule,
    RouterLink,
    ImageComponent,
    LineClampComponent,
    TuiButtonModule,
    AsyncPipe,
    ShowHideDirective,
  ],
})
export class ArticlesSectionComponent implements OnInit {
  private changeDetector = inject(ChangeDetectorRef);
  readonly breakpointObserver = inject(BreakpointObserver);

  @Input() data!: IArticlesSection;

  articleCategory!: IArticleCategory;

  @ViewChild('nav') nav?: ElementRef;
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

  swiperConfig = {
    breakpoints: {
      // when window width is >= 0px
      0: {
        slidesPerView: 1,
        centeredSlides: true,
      },
      // when window width is >= 960px
      960: {
        slidesPerView: 1.5,
        centeredSlides: false,
      },
    },
  };

  swiperContainerStyle: { [klass: string]: unknown } = {
    visibility: 'hidden',
  };

  activeIndex = 0;

  ngOnInit() {
    this.articleCategory = this.data.article_category.data;
  }

  slideNext() {
    this.swiper?.swiperRef.slideNext();
  }

  slidePrev() {
    this.swiper?.swiperRef.slidePrev();
  }

  slideTo(index: number) {
    this.swiper?.swiperRef.slideTo(index);
  }

  onSlideChange: SwiperEvents['slideChange'] = ({ activeIndex }) => {
    this.activeIndex = activeIndex;
    this.changeDetector.detectChanges();
  };

  onResize() {
    const left = this.nav?.nativeElement.getBoundingClientRect?.()?.right;
    if (left) {
      this.swiperContainerStyle = {
        position: 'absolute',
        left: left + 'px',
        right: 0,
      };
    } else {
      this.swiperContainerStyle = {};
    }
  }
}
