@if (partner) {
  <div>
    <ffb-image
      [url]="partner.attributes.logo.data.attributes.url"
      imageClass="logo mb3"
    ></ffb-image>
    <cms-rich-text
      class="mb3"
      [content]="partner.attributes.about"
    ></cms-rich-text>
    <a
      tuiButton
      icon="tuiIconArrowRight"
      [href]="partner.attributes.link"
      target="_blank"
      >
      {{ partner.attributes.linkLabel }}
    </a>
  </div>
}
