"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/**
 * @returns  True if the runtime is NodeJS.
 */
var isNode = function isNode() {
  return typeof module !== "undefined" && !!module.exports;
};
var _default = isNode;
exports["default"] = _default;
