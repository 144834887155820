"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "LoremIpsum", {
  enumerable: true,
  get: function get() {
    return _LoremIpsum["default"];
  }
});
exports.loremIpsum = void 0;
var _formats = require("./constants/formats");
var _units = require("./constants/units");
var _words = require("./constants/words");
var _LoremIpsum = _interopRequireDefault(require("./lib/LoremIpsum"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var loremIpsum = function loremIpsum() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    _ref$count = _ref.count,
    count = _ref$count === void 0 ? 1 : _ref$count,
    _ref$format = _ref.format,
    format = _ref$format === void 0 ? _formats.FORMAT_PLAIN : _ref$format,
    _ref$paragraphLowerBo = _ref.paragraphLowerBound,
    paragraphLowerBound = _ref$paragraphLowerBo === void 0 ? 3 : _ref$paragraphLowerBo,
    _ref$paragraphUpperBo = _ref.paragraphUpperBound,
    paragraphUpperBound = _ref$paragraphUpperBo === void 0 ? 7 : _ref$paragraphUpperBo,
    random = _ref.random,
    _ref$sentenceLowerBou = _ref.sentenceLowerBound,
    sentenceLowerBound = _ref$sentenceLowerBou === void 0 ? 5 : _ref$sentenceLowerBou,
    _ref$sentenceUpperBou = _ref.sentenceUpperBound,
    sentenceUpperBound = _ref$sentenceUpperBou === void 0 ? 15 : _ref$sentenceUpperBou,
    _ref$units = _ref.units,
    units = _ref$units === void 0 ? _units.UNIT_SENTENCES : _ref$units,
    _ref$words = _ref.words,
    words = _ref$words === void 0 ? _words.WORDS : _ref$words,
    _ref$suffix = _ref.suffix,
    suffix = _ref$suffix === void 0 ? "" : _ref$suffix;
  var options = {
    random: random,
    sentencesPerParagraph: {
      max: paragraphUpperBound,
      min: paragraphLowerBound
    },
    words: words,
    wordsPerSentence: {
      max: sentenceUpperBound,
      min: sentenceLowerBound
    }
  };
  var lorem = new _LoremIpsum["default"](options, format, suffix);
  switch (units) {
    case _units.UNIT_PARAGRAPHS:
    case _units.UNIT_PARAGRAPH:
      return lorem.generateParagraphs(count);
    case _units.UNIT_SENTENCES:
    case _units.UNIT_SENTENCE:
      return lorem.generateSentences(count);
    case _units.UNIT_WORDS:
    case _units.UNIT_WORD:
      return lorem.generateWords(count);
    default:
      return "";
  }
};
exports.loremIpsum = loremIpsum;
