import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { shareReplay } from 'rxjs/operators';
import { EnvironmentService } from '@lancelot-frontend/environment';
import { IContactTopic } from '../types';

@Injectable({
  providedIn: 'root',
})
export class ContactTopicsService {
  private http = inject(HttpClient);

  contactTopicsUrl =
    inject(EnvironmentService).get('cmsBaseUrl') + '/api/contact-topics';
  contactTopics$ = this._getContactTopics();

  private _getContactTopics() {
    const params = new HttpParams({ fromObject: { populate: '*.*' } });
    return this.http
      .get<{ data: IContactTopic[] }>(this.contactTopicsUrl, { params })
      .pipe(shareReplay({ bufferSize: 1, refCount: false }));
  }

  getContactTopics() {
    return this.contactTopics$;
  }
}
