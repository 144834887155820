<ng-container *tuiLet="breakpointObserver.ltSm$ | async as ltSm">
  @if (newsItem) {
    <div>
      <ffb-row maxWidth="3" [yPadding]="false">
        <div class="media">
          <cms-video-image-or-carousel
            [media]="newsItem.attributes.cover"
          ></cms-video-image-or-carousel>
        </div>
        <div class="my3">
          <cms-news-item-metadata
            [newsItem]="newsItem"
            mode="details"
          ></cms-news-item-metadata>
          <!--  Locale -->
          @if (newsItem.attributes.localizations.data[0]; as localization) {
            <div
              class="flex flex-row justify-end mt2"
              >
              <ffb-locale-button
                [locale]="localization.attributes.locale"
                [routerLink]="['..', localization.attributes.slug]"
                [replaceUrl]="true"
              ></ffb-locale-button>
            </div>
          }
        </div>
      </ffb-row>
      @for (block of newsItem.attributes.content; track block.id) {
        @if (block.__component === 'news.news-content') {
          <div
            class="news-content"
            >
            <ffb-row maxWidth="3" [yPadding]="false">
              <cms-rich-text [content]="block.content"></cms-rich-text>
            </ffb-row>
          </div>
        }
        @if (block.__component === 'navigation.call-to-action') {
          <div
            class="call-to-action"
            >
            <ffb-row maxWidth="3" [yPadding]="false">
              <a tuiButton appearance="accent" [href]="block.link" target="_blank">
                {{ block.label }}
              </a>
            </ffb-row>
          </div>
        }
        @if (block.__component === 'media.video-image-or-carousel') {
          <div
            class="video-image-or-carousel"
            >
            <ffb-row
              [maxWidth]="block.fullScreenWidth ? null : 3"
              [xPadding]="!block.fullScreenWidth"
              [yPadding]="false"
              >
              <cms-video-image-or-carousel
                [media]="block"
              ></cms-video-image-or-carousel>
            </ffb-row>
          </div>
        }
        @if (block.__component === 'media.media-grid') {
          <div class="media-grid">
            <cms-media-grid [items]="block.items.data"></cms-media-grid>
          </div>
        }
        @if (block.__component === 'media.calameo') {
          <div class="calameo">
            <ffb-row [yPadding]="false">
              <ffb-calameo-embed
                [publicationId]="block.publicationId"
                [aspectRatio]="ltSm ? 2 / 3 : 4 / 3"
              ></ffb-calameo-embed>
            </ffb-row>
          </div>
        }
        @if (block.__component === 'content.html-element') {
          <div class="html-element">
            <ffb-row maxWidth="3" [yPadding]="false">
              <cms-html-element
                [tag]="block.tag"
                [attributes]="block.attributes"
                [innerHTML]="block.innerHTML"
              ></cms-html-element>
            </ffb-row>
          </div>
        }
      }
    </div>
  }
</ng-container>
