import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { shareReplay } from 'rxjs/operators';
import { EnvironmentService } from '@lancelot-frontend/environment';
import { IHelpCategory } from '../types';

@Injectable({
  providedIn: 'root',
})
export class HelpService {
  private http = inject(HttpClient);

  helpCategoriesUrl =
    inject(EnvironmentService).get('cmsBaseUrl') + '/api/help-categories';
  helpCategories$ = this._getHelpCategories();

  private _getHelpCategories() {
    const params = new HttpParams({
      fromObject: { populate: '*.*' },
    });
    return this.http
      .get<{ data: IHelpCategory[] }>(this.helpCategoriesUrl, {
        params,
      })
      .pipe(shareReplay({ bufferSize: 1, refCount: false }));
  }

  getHelpCategories() {
    return this.helpCategories$;
  }
}
