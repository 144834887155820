"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FORMATS = exports.FORMAT_PLAIN = exports.FORMAT_HTML = void 0;
var FORMAT_HTML = "html";
exports.FORMAT_HTML = FORMAT_HTML;
var FORMAT_PLAIN = "plain";
exports.FORMAT_PLAIN = FORMAT_PLAIN;
var FORMATS = [FORMAT_HTML, FORMAT_PLAIN];
exports.FORMATS = FORMATS;
