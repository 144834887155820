import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { TuiButtonModule } from '@taiga-ui/core';
import { SwiperComponent, SwiperModule } from 'swiper/angular';
import { SwiperEvents } from 'swiper/types';
import { ImageComponent } from '@lancelot-frontend/components';
import { BreakpointObserver, ShowHideDirective } from '@lancelot-frontend/core';
import { ICallToAction, ITestimonial, ITestimonialsSection } from '../../types';

@Component({
  selector: 'cms-testimonials-section',
  templateUrl: './testimonials-section.component.html',
  styleUrls: ['./testimonials-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    NgTemplateOutlet,
    SwiperModule,
    ImageComponent,
    TuiButtonModule,
    AsyncPipe,
    ShowHideDirective,
  ],
})
export class TestimonialsSectionComponent implements OnInit {
  private changeDetector = inject(ChangeDetectorRef);
  readonly breakpointObserver = inject(BreakpointObserver);

  @Input() data!: ITestimonialsSection;

  testimonials!: ITestimonial[];
  title?: string;
  subtitle?: string;
  callToAction?: ICallToAction;

  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

  swiperConfig = {
    loop: true,
    centeredSlides: true,
    slideToClickedSlide: true,
    breakpoints: {
      // when window width is >= 0px
      0: {
        slidesPerView: 1,
        spaceBetween: 16,
      },
      // when window width is >= 600px
      600: {
        slidesPerView: 3,
      },
      // when window width is >= 960px
      960: {
        slidesPerView: 5,
      },
    },
  };

  activeIndex = 0;

  ngOnInit() {
    this.testimonials = this.data.testimonials.data;
    this.title = this.data.title;
    this.subtitle = this.data.subtitle;
    this.callToAction = this.data.callToAction;
  }

  slideNext() {
    this.swiper?.swiperRef.slideNext();
  }

  slidePrev() {
    this.swiper?.swiperRef.slidePrev();
  }

  onRealIndexChange: SwiperEvents['realIndexChange'] = ({ realIndex }) => {
    this.activeIndex = realIndex % this.testimonials.length;
    this.changeDetector.detectChanges();
  };
}
