import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { stringify } from 'qs';
import { EnvironmentService } from '@lancelot-frontend/environment';
import { IPagination, IPressFile } from '../types';

@Injectable({
  providedIn: 'root',
})
export class PressFilesService {
  private http = inject(HttpClient);

  pressFilesUrl =
    inject(EnvironmentService).get('cmsBaseUrl') + '/api/press-files';

  getPressFiles(params: Record<string, unknown> = {}) {
    const httpParams = new HttpParams({
      fromString: stringify({
        populate: '*.*',
        ...params,
      }),
    });
    return this.http.get<{ data: IPressFile[]; meta: IPagination }>(
      this.pressFilesUrl,
      {
        params: httpParams,
      },
    );
  }
}
