<div *ffbContentAdornment="4; backgroundColor: 'light'">
  <ffb-row>
    <div class="flex flex-row flex-wrap gap2">
      <div class="column flex-auto">
        <ng-container *hide.ltMd="true">
          @for (item of items; track item.id; let i = $index) {
            <div>
              @if (i % 3 === 0) {
                <ng-container
                  *ngTemplateOutlet="itemTemplate; context: { item: item }"
                ></ng-container>
              }
            </div>
          }
        </ng-container>
        <ng-container *show.ltMd="true">
          <ng-container *hide.ltSm="true">
            @for (item of items; track item.id; let i = $index) {
              <div>
                @if (i % 2 === 0) {
                  <ng-container
                    *ngTemplateOutlet="itemTemplate; context: { item: item }"
                  ></ng-container>
                }
              </div>
            }
          </ng-container>
        </ng-container>
        <ng-container *show.ltSm="true">
          @for (item of items; track item.id; let i = $index) {
            <div>
              <ng-container
                *ngTemplateOutlet="itemTemplate; context: { item: item }"
              ></ng-container>
            </div>
          }
        </ng-container>
      </div>
      <div *hide.ltSm="true" class="column flex-auto">
        <ng-container *hide.ltMd="true">
          @for (item of items; track item.id; let i = $index) {
            <div>
              @if (i % 3 === 1) {
                <ng-container
                  *ngTemplateOutlet="itemTemplate; context: { item: item }"
                ></ng-container>
              }
            </div>
          }
        </ng-container>
        <ng-container *show.ltMd="true">
          @for (item of items; track item.id; let i = $index) {
            <div>
              @if (i % 2 === 1) {
                <ng-container
                  *ngTemplateOutlet="itemTemplate; context: { item: item }"
                ></ng-container>
              }
            </div>
          }
        </ng-container>
      </div>
      <div *hide.ltMd="true" class="column flex-auto">
        @for (item of items; track item.id; let i = $index) {
          <div>
            @if (i % 3 === 2) {
              <ng-container
                *ngTemplateOutlet="itemTemplate; context: { item: item }"
              ></ng-container>
            }
          </div>
        }
      </div>
    </div>
  </ffb-row>
</div>

<ng-template #itemTemplate let-item="item">
  <div class="border-radius-l overflow-hidden">
    @if (item.attributes.mime.startsWith('image')) {
      <ffb-image
        [url]="item.attributes.url"
        [alternativeText]="item.attributes.alternativeText"
        [width]="item.attributes.width"
        [height]="item.attributes.height"
      ></ffb-image>
    }
    @if (item.attributes.mime.startsWith('video')) {
      <ffb-video
        [url]="item.attributes.url"
        [mime]="item.attributes.mime"
      ></ffb-video>
    }
  </div>
</ng-template>
