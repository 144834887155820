import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslocoDirective } from '@ngneat/transloco';
import { TranslocoDatePipe } from '@ngneat/transloco-locale';
import { ActionComponent } from '@lancelot-frontend/components';
import { ILeMondeBridgeProblemSolution } from '../../types';

@Component({
  selector: 'cms-le-monde-bridge-problem-solutions-list-item',
  templateUrl: './le-monde-bridge-problem-solutions-list-item.component.html',
  styleUrls: ['./le-monde-bridge-problem-solutions-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoDirective, ActionComponent, TranslocoDatePipe],
})
export class LeMondeBridgeProblemSolutionsListItemComponent {
  @Input() leMondeBridgeProblemSolution!: ILeMondeBridgeProblemSolution;
}
