import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  inject,
} from '@angular/core';
import { TuiLetModule } from '@taiga-ui/cdk';
import { TuiLinkModule } from '@taiga-ui/core';
import { LineClampComponent } from '@lancelot-frontend/components';
import { BreakpointObserver } from '@lancelot-frontend/core';
import { INewsContent, INewsItem } from '../../types';
import { NewsItemMetadataComponent } from '../news-item-metadata/news-item-metadata.component';
import { RichTextComponent } from '../rich-text/rich-text.component';
import { VideoImageOrCarouselComponent } from '../video-image-or-carousel/video-image-or-carousel.component';

@Component({
  selector: 'cms-news-list-item',
  templateUrl: './news-list-item.component.html',
  styleUrls: ['./news-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    TuiLetModule,
    TuiLinkModule,
    VideoImageOrCarouselComponent,
    LineClampComponent,
    RichTextComponent,
    NewsItemMetadataComponent,
  ],
})
export class NewsListItemComponent implements OnInit, OnChanges {
  readonly breakpointObserver = inject(BreakpointObserver);

  @Input() newsItem: INewsItem | undefined;
  @Input() mode: 'banner' | 'featured' | 'grid-cell' = 'grid-cell';
  @Input() loading = false;

  firstParagraph?: string;

  ngOnInit() {
    this.setFirstParagraph();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.newsItem && !changes.newsItem.firstChange) {
      this.setFirstParagraph();
    }
  }

  setFirstParagraph() {
    this.firstParagraph = (
      this.newsItem?.attributes.content?.find(
        (block) => block.__component === 'news.news-content',
      ) as INewsContent
    )?.content;
  }

  stopEventPropagation(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
  }
}
