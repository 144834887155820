import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { stringify } from 'qs';
import { map, shareReplay } from 'rxjs/operators';
import { EnvironmentService } from '@lancelot-frontend/environment';
import { IPartner } from '../types';

@Injectable({
  providedIn: 'root',
})
export class PartnersService {
  private http = inject(HttpClient);

  partnersUrl = inject(EnvironmentService).get('cmsBaseUrl') + '/api/partners';
  partners$ = this._getPartners();

  private _getPartners() {
    const params = new HttpParams({
      fromString: stringify({ populate: '*.*', pagination: { pageSize: 100 } }),
    });
    return this.http
      .get<{ data: IPartner[] }>(this.partnersUrl, { params })
      .pipe(shareReplay({ bufferSize: 1, refCount: false }));
  }

  getPartners() {
    return this.partners$;
  }

  getPartner(slug: string) {
    return this.partners$.pipe(
      map(({ data: partners }) => {
        const partner = partners.find((p) => p.attributes.slug === slug);
        if (!partner) {
          throw new HttpErrorResponse({ status: 404 });
        }
        return { data: partner };
      }),
    );
  }
}
