<ng-container *transloco="let t">
  @if (leMondeBridgeProblemSolution) {
    <div
      class="wrapper flex"
    [ngClass]="{
      'flex-row': (breakpointObserver.ltSm$ | async) === false,
      'flex-column': (breakpointObserver.ltSm$ | async) === true
    }"
      >
      <div class="cover bg-cards-02"></div>
      <div>
        <div
          *ffbContentAdornment="5"
          class="color-text-01-night"
        [ngClass]="{
          'py4 px2': (breakpointObserver.ltSm$ | async) === true,
          p4: (breakpointObserver.gtXs$ | async) === true
        }"
          >
          <div
          [ngClass]="{
            py4: (breakpointObserver.gtXs$ | async) === true,
            py2: (breakpointObserver.ltSm$ | async) === true
          }"
            >
            <tui-svg src="/assets/images/m-le-mag-logo.svg"></tui-svg>
            @if (date) {
              <h4 class="tui-text_h4 mb3 mt1">
                {{
                t('cms.leMondeBridgeProblemSolution.title', {
                date:
                date
                | translocoDate
                : { day: '2-digit', month: 'long', year: 'numeric' }
                })
                }}
              </h4>
            }
            <p class="mb2">
              {{ subtitle }}
            </p>
            <a
              tuiButton
              icon="tuiIconArrowRight"
            [routerLink]="[
              'le-monde-bridge-problems',
              'solutions',
              leMondeBridgeProblemSolution.attributes.number
            ]"
            [ngClass]="{
              'full-width': (breakpointObserver.ltSm$ | async) === true
            }"
              >
              {{ linkLabelToSolution }}
            </a>
          </div>
        </div>
      </div>
    </div>
  }
</ng-container>
