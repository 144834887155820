<div
  class="articles-section"
  *ffbContentAdornment="3; backgroundColor: 'light'"
  (waResizeObserver)="onResize()"
  >
  <ffb-row>
    <div class="text center">
      <h4 class="tui-text_h4 mb3">{{ articleCategory.attributes.name }}</h4>
      @if (articleCategory.attributes.description) {
        <h6
          class="tui-text_h6 mb3"
          >
          {{ articleCategory.attributes.description }}
        </h6>
      }
    </div>
    <div class="flex flex-row items-start">
      <div
        *hide.ltMd="true"
        #nav
        class="nav-wrapper flex flex-column justify-center items-start pl2 pr4"
        >
        <div class="nav pr4">
          @for (
            article of articleCategory.attributes.articles.data; track
            article; let i = $index) {
            <div
              [ngClass]="{ mt3: i > 0 }"
              >
              <button
                tuiLink
                [ngClass]="{ active: activeIndex === i }"
                (click)="slideTo(i)"
                >
                <h6 class="tui-text_h6">
                  {{ article.attributes.title }}
                </h6>
              </button>
            </div>
          }
        </div>
      </div>
      <div class="articles-wrapper" [ngStyle]="swiperContainerStyle">
        <swiper
          #swiper
          [config]="swiperConfig"
          (slideChange)="onSlideChange($event[0])"
          class="articles"
          >
          @for (article of articleCategory.attributes.articles.data; track article.id) {
            <ng-template
              swiperSlide
              >
              <div class="flex flex-row">
                <a
                  class="article flex flex-row flex-0 items-stretch justify-start content-stretch p2 bg-white"
                [ngClass]="{
                  'flex-row': (breakpointObserver.ltSm$ | async) === false,
                  'flex-column': (breakpointObserver.ltSm$ | async) === true
                }"
                [routerLink]="[
                  'about-bridge',
                  articleCategory.attributes.slug,
                  article.attributes.slug
                ]"
                  [state]="{ scrollBehavior: 'GO_TO_TOP' }"
                  >
                  <ffb-image
                    [url]="article.attributes.thumbnail.data.attributes.url"
                    [aspectRatio]="1 / 1"
                    disposition="background"
                    backgroundClass="cover"
                  ></ffb-image>
                  <div class="p2">
                    <h6 class="title tui-text_h6 mb2">
                      {{ article.attributes.title }}
                    </h6>
                    <ffb-line-clamp
                      [content]="content"
                      [linesLimit]="5"
                      class="content-wrapper mb2"
                    ></ffb-line-clamp>
                    <ng-template #content>
                      <div class="content">
                        @if (article.attributes.intro) {
                          <div
                            [innerHTML]="article.attributes.intro"
                          ></div>
                        }
                        @if (article.attributes.intro) {
                          <br />
                        }
                        <div [innerHTML]="article.attributes.content"></div>
                      </div>
                    </ng-template>
                    <a
                      tuiLink
                      class="color-accent"
                      icon="tuiIconArrowRight"
                      iconAlign="left"
                      >
                      {{ article.attributes.linkLabelToArticleDetails }}
                    </a>
                  </div>
                </a>
              </div>
            </ng-template>
          }
        </swiper>
      </div>
    </div>
    <div *show.ltMd="true" class="flex flex-row justify-center gap2 pt3 pb2">
      <button
        tuiIconButton
        icon="tuiIconArrowLeft"
        appearance="secondary"
        shape="rounded"
        (click)="slidePrev()"
        [disabled]="activeIndex === 0"
      ></button>
      <button
        tuiIconButton
        icon="tuiIconArrowRight"
        appearance="secondary"
        shape="rounded"
        (click)="slideNext()"
        [disabled]="
          activeIndex === articleCategory.attributes.articles.data.length - 1
        "
      ></button>
    </div>
  </ffb-row>
</div>
