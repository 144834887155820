import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { shareReplay } from 'rxjs/operators';
import { EnvironmentService } from '@lancelot-frontend/environment';
import {
  IFederationPage,
  IHomePage,
  ILegalNoticePage,
  IPage,
  IPressPage,
  IPrivacyPolicyPage,
} from '../types';

@Injectable({
  providedIn: 'root',
})
export class PagesService {
  private http = inject(HttpClient);
  private environmentService = inject(EnvironmentService);

  pagesUrls = this.environmentService.get('cmsBaseUrl') + '/api/pages';
  homePageUrl = this.environmentService.get('cmsBaseUrl') + '/api/home-page';
  pressPageUrl = this.environmentService.get('cmsBaseUrl') + '/api/press-page';
  legalNoticePageUrl =
    this.environmentService.get('cmsBaseUrl') + '/api/legal-notice-page';
  privacyPolicyPageUrl =
    this.environmentService.get('cmsBaseUrl') + '/api/privacy-policy-page';
  federationPageUrl =
    this.environmentService.get('cmsBaseUrl') + '/api/federation-page';
  homePage$ = this._getHomePage();
  pressPage$ = this._getPressPage();
  legalNoticePage$ = this._getLegalNoticePage();
  privacyPolicyPage$ = this._getPrivacyPolicyPage();
  federationPage$ = this._getFederationPage();

  getPage(slug: string) {
    const url = `${this.pagesUrls}/${slug}`;
    const params = new HttpParams({ fromObject: { populate: '*.*' } });
    return this.http.get<{ data: IPage }>(url, { params });
  }

  private _getHomePage() {
    const params = new HttpParams({ fromObject: { populate: '*.*' } });
    return this.http
      .get<{ data: IHomePage }>(this.homePageUrl, { params })
      .pipe(shareReplay({ bufferSize: 1, refCount: false }));
  }

  getHomePage() {
    return this.homePage$;
  }

  private _getPressPage() {
    const params = new HttpParams({ fromObject: { populate: '*.*' } });
    return this.http
      .get<{ data: IPressPage }>(this.pressPageUrl, { params })
      .pipe(shareReplay({ bufferSize: 1, refCount: false }));
  }

  getPressPage() {
    return this.pressPage$;
  }

  private _getLegalNoticePage() {
    const params = new HttpParams({ fromObject: { populate: '*.*' } });
    return this.http
      .get<{ data: ILegalNoticePage }>(this.legalNoticePageUrl, { params })
      .pipe(shareReplay({ bufferSize: 1, refCount: false }));
  }

  getLegalNoticePage() {
    return this.legalNoticePage$;
  }

  private _getPrivacyPolicyPage() {
    const params = new HttpParams({ fromObject: { populate: '*.*' } });
    return this.http
      .get<{ data: IPrivacyPolicyPage }>(this.privacyPolicyPageUrl, { params })
      .pipe(shareReplay({ bufferSize: 1, refCount: false }));
  }

  getPrivacyPolicyPage() {
    return this.privacyPolicyPage$;
  }

  private _getFederationPage() {
    const params = new HttpParams({ fromObject: { populate: '*.*' } });
    return this.http
      .get<{ data: IFederationPage }>(this.federationPageUrl, { params })
      .pipe(shareReplay({ bufferSize: 1, refCount: false }));
  }

  getFederationPage() {
    return this.federationPage$;
  }
}
