import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { TuiButtonModule } from '@taiga-ui/core';
import { SwiperComponent, SwiperModule } from 'swiper/angular';
import { ImageComponent } from '@lancelot-frontend/components';
import { ShowHideDirective } from '@lancelot-frontend/core';
import { IPartner, IPartnersSection } from '../../types';

@Component({
  selector: 'cms-partners-section',
  templateUrl: './partners-section.component.html',
  styleUrls: ['./partners-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgTemplateOutlet,
    SwiperModule,
    RouterLink,
    ImageComponent,
    TuiButtonModule,
    ShowHideDirective,
  ],
})
export class PartnersSectionComponent implements OnInit {
  @Input() data!: IPartnersSection;

  partners!: IPartner[];
  title!: string;

  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

  swiperConfig = {
    loop: true,
    breakpoints: {
      // when window width is >= 0px
      0: {
        slidesPerView: 1.5,
        spaceBetween: 16,
        centeredSlides: true,
      },
      // when window width is >= 600px
      600: {
        slidesPerView: 3.8,
        spaceBetween: 16,
        centeredSlides: true,
      },
      // when window width is >= 960px
      960: {
        slidesPerView: 4.8,
        spaceBetween: 16,
        centeredSlides: true,
      },
      1280: {
        slidesPerView: 6,
        spaceBetween: 16,
        centeredSlides: false,
      },
    },
  };

  ngOnInit() {
    this.partners = this.data.partners.data;
    this.title = this.data.title;
  }

  slideNext() {
    this.swiper?.swiperRef.slideNext();
  }

  slidePrev() {
    this.swiper?.swiperRef.slidePrev();
  }
}
