<ng-container *transloco="let t">
  <ffb-action>
    <div class="full-width full-height pr4">
      <h6 class="tui-text_h6 leMondeBridgeProblemSolution-title">
        {{
          t('cms.leMondeBridgeProblemSolution.title', {
            date:
              leMondeBridgeProblemSolution.attributes.date
              | translocoDate
                : { day: '2-digit', month: 'long', year: 'numeric' }
          })
        }}
      </h6>
      <h6 class="tui-text_h6 leMondeBridgeProblemSolution-number">
        N°{{ leMondeBridgeProblemSolution.attributes.number }}
      </h6>
    </div>
  </ffb-action>
</ng-container>
