<div class="news-section">
  @if (displayTitle && title) {
    <h4 class="title tui-text_h4 mb3">
      {{ title }}
    </h4>
  }
  <swiper [config]="swiperConfig" class="news-items">
    @for (newsItem of newsItems; track newsItem.id) {
      <ng-template swiperSlide>
        <a
          class="fit"
          [routerLink]="!loading ? ['/news', newsItem.attributes.slug] : null"
          >
          <cms-news-list-item
            [newsItem]="newsItem"
            [loading]="loading"
          ></cms-news-list-item>
        </a>
      </ng-template>
    }
  </swiper>
  @if (linkToAllNews && linkLabelToAllNews) {
    <div class="center px2">
      <button
        tuiButton
        icon="tuiIconArrowRight"
        class="mt2"
        [ngClass]="{ 'full-width': (breakpointObserver.ltSm$ | async) === true }"
        routerLink="/news"
        [queryParams]="linkToAllNewsQueryParams"
        >
        {{ linkLabelToAllNews }}
      </button>
    </div>
  }
</div>
