import { AsyncPipe, NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  inject,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslocoDirective } from '@ngneat/transloco';
import { TranslocoDatePipe } from '@ngneat/transloco-locale';
import { TuiButtonModule, TuiSvgModule } from '@taiga-ui/core';
import { ContentAdornmentDirective } from '@lancelot-frontend/components';
import { BreakpointObserver } from '@lancelot-frontend/core';
import {
  ILeMondeBridgeProblemSolution,
  ILeMondeBridgeProblemSolutionsSection,
} from '../../types';

@Component({
  selector: 'cms-le-monde-bridge-problem-solutions-section',
  templateUrl: './le-monde-bridge-problem-solutions-section.component.html',
  styleUrls: ['./le-monde-bridge-problem-solutions-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoDirective,
    NgClass,
    ContentAdornmentDirective,
    TuiSvgModule,
    TuiButtonModule,
    RouterLink,
    AsyncPipe,
    TranslocoDatePipe,
  ],
})
export class LeMondeBridgeProblemSolutionsSectionComponent implements OnInit {
  readonly breakpointObserver = inject(BreakpointObserver);

  @Input() data!: ILeMondeBridgeProblemSolutionsSection;

  leMondeBridgeProblemSolution?: ILeMondeBridgeProblemSolution;
  subtitle?: string;
  date?: Date;
  linkLabelToSolution?: string;

  ngOnInit() {
    this.leMondeBridgeProblemSolution =
      this.data.last_le_monde_bridge_problem_solution.data;
    this.subtitle = this.data.subtitle;
    this.date =
      this.data.last_le_monde_bridge_problem_solution.data.attributes.date;
    this.linkLabelToSolution = this.data.linkLabelToSolution;
  }
}
