<div class="partners-section">
  <h4 class="title tui-text_h4 mb3 center">
    {{ title }}
  </h4>
  <div class="flex flex-row items-center">
    <div *hide.ltMd="true" class="pr2">
      <ng-container *ngTemplateOutlet="arrowLeft"></ng-container>
    </div>
    <swiper #swiper [config]="swiperConfig" class="partners">
      @for (partner of partners; track partner.id) {
        <ng-template swiperSlide>
          <div class="flex flex-row justify-center items-center full-height px2">
            <a
              class="partner"
              [routerLink]="['partners', partner.attributes.slug]"
              >
              <ffb-image
                [url]="partner.attributes.logo.data.attributes.url"
              [alternativeText]="
                partner.attributes.logo.data.attributes.alternativeText
              "
                [width]="partner.attributes.logo.data.attributes.width"
                [height]="partner.attributes.logo.data.attributes.height"
                imageClass="full-width"
              ></ffb-image>
            </a>
          </div>
        </ng-template>
      }
    </swiper>
    <div *hide.ltMd="true" class="pl2">
      <ng-container *ngTemplateOutlet="arrowRight"></ng-container>
    </div>
  </div>
</div>
<div *show.ltMd="true" class="flex flex-row justify-center gap2 pt3">
  <ng-container *ngTemplateOutlet="arrowLeft"></ng-container>
  <ng-container *ngTemplateOutlet="arrowRight"></ng-container>
</div>
<ng-template #arrowLeft>
  <button
    tuiIconButton
    icon="tuiIconArrowLeft"
    appearance="secondary"
    shape="rounded"
    (click)="slidePrev()"
  ></button>
</ng-template>
<ng-template #arrowRight>
  <button
    tuiIconButton
    icon="tuiIconArrowRight"
    appearance="secondary"
    shape="rounded"
    (click)="slideNext()"
  ></button>
</ng-template>
