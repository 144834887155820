import { isPlatformServer } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  PLATFORM_ID,
  Renderer2,
  ViewChild,
  inject,
} from '@angular/core';

@Component({
  selector: 'cms-html-element',
  templateUrl: './html-element.component.html',
  styleUrls: ['./html-element.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class HtmlElementComponent implements AfterViewInit {
  private platformId = inject(PLATFORM_ID);
  private renderer2 = inject(Renderer2);

  @Input() tag!: string;
  @Input() attributes!: Record<string, string> | null;
  @Input() innerHTML!: null | string;

  @ViewChild('container') container?: ElementRef;

  ngAfterViewInit() {
    if (!isPlatformServer(this.platformId) || this.tag !== 'script') {
      const HTMLElement = this.renderer2.createElement(this.tag);

      if (this.attributes) {
        Object.keys(this.attributes).forEach((key) => {
          HTMLElement.setAttribute(key, this.attributes?.[key]);
        });
      }

      if (this.innerHTML) {
        HTMLElement.innerHTML = this.innerHTML;
      }

      this.renderer2.appendChild(this.container?.nativeElement, HTMLElement);
    }
  }
}
