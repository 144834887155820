import { loremIpsum } from 'lorem-ipsum';
import { alea as seedRandom } from 'seedrandom';
import { IPartner } from '../types';
import { contentType } from './content-type.mock';
import { getMockedFile } from './file.mock';

export const getMockedPartners = (n = 3): IPartner[] => {
  const partners = [];

  do {
    const id = (partners.length + 1) as number;
    partners.push({
      id,
      attributes: {
        slug: `partner_${id}`,
        name: `Partner ${id}`,
        logo: { data: getMockedFile() },
        about: loremIpsum({
          count: 2,
          units: 'paragraphs',
          random: seedRandom(id.toString()),
        }),
        link: `http://partner.${id}.link`,
        linkLabel: 'More information',
        ...contentType,
      },
    });
  } while (partners.length < n);

  return partners;
};
export const getMockedPartner = () => getMockedPartners(1)[0];
