import { loremIpsum } from 'lorem-ipsum';
import { alea as seedRandom } from 'seedrandom';
import { INewsItem } from '../types';
import { localizedContentType } from './content-type.mock';
import { getMockedFile } from './file.mock';
import { getMockedNewsCategories } from './news-category.mock';

export const getMockedNewsItems = (n = 3): INewsItem[] => {
  const newsItems = [];

  do {
    const id = (newsItems.length + 1) as number;
    newsItems.push({
      id,
      attributes: {
        slug: `news_item_${id}`,
        title: `Title of news item ${id}`,
        content: [
          {
            __component: 'news.news-content',
            content: loremIpsum({
              count: 2,
              units: 'paragraphs',
              random: seedRandom(id.toString()),
            }),
          },
        ] as INewsItem['attributes']['content'],
        cover: {
          id,
          image: { data: getMockedFile() },
          video: { data: null },
          carousel: { data: null },
        },
        news_categories: getMockedNewsCategories(2, { nested: true }),
        linkLabelToNewsItemDetails: 'Read more',
        ...localizedContentType,
      },
    });
  } while (newsItems.length < n);

  return newsItems;
};

export const getMockedNewsItem = () => getMockedNewsItems(1)[0];
