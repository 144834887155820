@if (testimonials) {
  <div
    class="wrapper flex"
  [ngClass]="{
    'flex-row': (breakpointObserver.ltSm$ | async) === false,
    'flex-column': (breakpointObserver.ltSm$ | async) === true
  }"
    >
    <div [ngClass]="{ pr4: (breakpointObserver.gtSm$ | async) === true }">
      <div
      [ngClass]="{
        'pt2 pr2': (breakpointObserver.gtXs$ | async) === true,
        'pt3 pr4': (breakpointObserver.gtSm$ | async) === true
      }"
        >
        <h4 class="tui-text_h4 mb2">{{ title }}</h4>
        <p class="mb3">{{ subtitle }}</p>
        <div *hide.ltSm="true">
          @if (callToAction) {
            <div class="mb3">
              <ng-container
            *ngTemplateOutlet="
              callToActionTemplate;
              context: { callToAction: callToAction }
            "
              ></ng-container>
            </div>
          }
          <ng-container *ngTemplateOutlet="arrows"></ng-container>
        </div>
      </div>
    </div>
    <div class="testimonials">
      <swiper
        #swiper
        [config]="swiperConfig"
        (realIndexChange)="onRealIndexChange($event[0])"
        >
        @for (testimonial of testimonials; track testimonial.id) {
          <ng-template swiperSlide>
            <div class="portrait-wrapper flex flex-row justify-center items-center">
              <ffb-image
                [url]="testimonial.attributes.portrait.data.attributes.url"
                [aspectRatio]="1 / 1"
                disposition="background"
                backgroundClass="portrait"
              ></ffb-image>
            </div>
          </ng-template>
        }
      </swiper>
      <div class="testimonial center">
        <h5 class="tui-text_h6 mb2">
          "{{ testimonials[activeIndex].attributes.quote }}"
        </h5>
        <p>{{ testimonials[activeIndex].attributes.firstName }}</p>
      </div>
    </div>
    <div *show.ltSm="true" class="center">
      <div class="my3">
        <ng-container *ngTemplateOutlet="arrows"></ng-container>
      </div>
      <ng-container
      *ngTemplateOutlet="
        callToActionTemplate;
        context: { callToAction: callToAction }
      "
      ></ng-container>
    </div>
  </div>
}

<ng-template #arrows>
  <div
    class="flex flex-row gap2"
    [ngClass]="{
      'justify-center': (breakpointObserver.ltSm$ | async) === true
    }"
    >
    <button
      tuiIconButton
      icon="tuiIconArrowLeft"
      appearance="secondary"
      shape="rounded"
      (click)="slidePrev()"
    ></button>
    <button
      tuiIconButton
      icon="tuiIconArrowRight"
      appearance="secondary"
      shape="rounded"
      (click)="slideNext()"
    ></button>
  </div>
</ng-template>

<ng-template #callToActionTemplate let-callToAction="callToAction">
  @if (callToAction) {
    <a
      tuiButton
      icon="tuiIconArrowRight"
      [href]="callToAction.link"
      [ngClass]="{ 'full-width': (breakpointObserver.ltSm$ | async) === true }"
      >
      {{ callToAction.label }}
    </a>
  }
</ng-template>
