import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { shareReplay } from 'rxjs/operators';
import { EnvironmentService } from '@lancelot-frontend/environment';
import { IFooter } from '../types';

@Injectable({
  providedIn: 'root',
})
export class FooterService {
  private http = inject(HttpClient);

  footerUrl = inject(EnvironmentService).get('cmsBaseUrl') + '/api/footer';
  footer$ = this._getFooter();

  private _getFooter() {
    const params = new HttpParams({ fromObject: { populate: '*.*' } });
    return this.http
      .get<{ data: IFooter }>(this.footerUrl, { params })
      .pipe(shareReplay({ bufferSize: 1, refCount: false }));
  }

  getFooter() {
    return this.footer$;
  }
}
