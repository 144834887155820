import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { TuiLetModule } from '@taiga-ui/cdk';
import { TuiButtonModule } from '@taiga-ui/core';
import {
  CalameoEmbedComponent,
  LocaleButtonComponent,
  RowComponent,
} from '@lancelot-frontend/components';
import { BreakpointObserver } from '@lancelot-frontend/core';
import { INewsItem } from '../../types';
import { HtmlElementComponent } from '../html-element/html-element.component';
import { MediaGridComponent } from '../media-grid/media-grid.component';
import { NewsItemMetadataComponent } from '../news-item-metadata/news-item-metadata.component';
import { RichTextComponent } from '../rich-text/rich-text.component';
import { VideoImageOrCarouselComponent } from '../video-image-or-carousel/video-image-or-carousel.component';

@Component({
  selector: 'cms-news-item-details',
  templateUrl: './news-item-details.component.html',
  styleUrls: ['./news-item-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TuiLetModule,
    RowComponent,
    VideoImageOrCarouselComponent,
    NewsItemMetadataComponent,
    LocaleButtonComponent,
    RouterLink,
    RichTextComponent,
    TuiButtonModule,
    MediaGridComponent,
    AsyncPipe,
    CalameoEmbedComponent,
    HtmlElementComponent,
  ],
})
export class NewsItemDetailsComponent {
  readonly breakpointObserver = inject(BreakpointObserver);

  @Input() newsItem: INewsItem | undefined;
}
