import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  inject,
} from '@angular/core';
import {
  CaptionComponent,
  CarouselComponent,
  ImageComponent,
  VideoComponent,
} from '@lancelot-frontend/components';
import { IFile } from '../../types/file.interface';
import { IVideoImageOrCarousel } from '../../types/video-image-or-carousel.interface';

@Component({
  selector: 'cms-video-image-or-carousel',
  templateUrl: './video-image-or-carousel.component.html',
  styleUrls: ['./video-image-or-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    VideoComponent,
    ImageComponent,
    CarouselComponent,
    CaptionComponent,
  ],
})
export class VideoImageOrCarouselComponent implements OnChanges {
  private changeDetector = inject(ChangeDetectorRef);

  @Input() media?: IVideoImageOrCarousel;
  @Input() disposition?: 'background' | 'free' | 'fullScreenWidth';
  @Input() aspectRatio?: number;
  @Input() controls?: 'simple' | boolean;
  @Input() autoplay?: boolean;

  video?: IFile['attributes'];
  carousel?: IFile['attributes'][];
  image?: IFile['attributes'];
  caption?: string;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.media) {
      this.setMedia();
    }
  }

  setMedia() {
    this.video = undefined;
    this.carousel = undefined;
    this.image = undefined;
    this.caption = undefined;

    this.video = this.media?.video.data?.attributes;

    if (!this.media?.video.data && this.media?.carousel?.data?.length) {
      this.carousel = this.media?.carousel.data.map(
        ({ attributes }) => attributes,
      );
    }

    if (!this.media?.video.data && !this.media?.carousel?.data?.length) {
      this.image = this.media?.image.data?.attributes;
    }

    const media = this.video || this.image;
    if (media) {
      this.caption = media.caption !== media.name ? media.caption : undefined;
    }

    if (this.disposition === undefined && this.media) {
      this.disposition = this.media.fullScreenWidth
        ? 'fullScreenWidth'
        : 'free';
    }
    if (this.controls === undefined) {
      this.controls = this.disposition !== 'background';
    }
    if (this.autoplay === undefined) {
      this.autoplay = this.media?.autoplay;
    }

    this.changeDetector.detectChanges();
  }
}
