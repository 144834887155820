import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { SafePipe } from '@lancelot-frontend/core';

@Component({
  selector: 'cms-rich-text',
  templateUrl: './rich-text.component.html',
  styleUrls: ['./rich-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SafePipe],
})
export class RichTextComponent implements AfterViewInit {
  @Input() content = '';

  @ViewChild('container') container?: ElementRef;

  ngAfterViewInit() {
    this.container?.nativeElement
      ?.querySelectorAll('a')
      .forEach?.((a: HTMLAnchorElement) => {
        a.setAttribute('target', '_blank');
        if (a.getElementsByTagName('img').length > 0) {
          a.classList.add('linkImage');
        }
      });
  }
}
