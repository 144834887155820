import { loremIpsum } from 'lorem-ipsum';
import { alea as seedRandom } from 'seedrandom';
import { ITestimonial } from '../types';
import { contentType } from './content-type.mock';
import { getMockedFile } from './file.mock';

export const getMockedTestimonials = (n = 3): ITestimonial[] => {
  const testimonials = [];

  do {
    const id = (testimonials.length + 1) as number;
    testimonials.push({
      id,
      attributes: {
        firstName: `First Name ${id}`,
        portrait: { data: getMockedFile() },
        quote: loremIpsum({
          count: 1,
          units: 'paragraphs',
          random: seedRandom(id.toString()),
        }),
        ...contentType,
      },
    });
  } while (testimonials.length < n);

  return testimonials;
};
export const getMockedTestimonial = () => getMockedTestimonials(1)[0];
