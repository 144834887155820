import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { stringify } from 'qs';
import { EnvironmentService } from '@lancelot-frontend/environment';
import { INewsCategory, INewsItem, IPagination } from '../types';

@Injectable({
  providedIn: 'root',
})
export class NewsService {
  private http = inject(HttpClient);
  private environmentService = inject(EnvironmentService);

  newsCategoriesUrl =
    this.environmentService.get('cmsBaseUrl') + '/api/news-categories';
  newsUrl = this.environmentService.get('cmsBaseUrl') + '/api/news-items';

  getNewsCategories(params: Record<string, unknown> = {}) {
    const httpParams = new HttpParams({
      fromString: stringify(params),
    });
    return this.http.get<{ data: INewsCategory[]; meta: IPagination }>(
      this.newsCategoriesUrl,
      { params: httpParams },
    );
  }

  getNewsItems(params: Record<string, unknown> = {}) {
    const httpParams = new HttpParams({
      fromString: stringify({
        populate: '*.*',
        ...params,
      }),
    });
    return this.http.get<{ data: INewsItem[]; meta: IPagination }>(
      this.newsUrl,
      { params: httpParams },
    );
  }

  getNewsItem(slug: string) {
    const url = `${this.newsUrl}/${slug}`;
    const params = new HttpParams({ fromObject: { populate: '*.*' } });
    return this.http.get<{ data: INewsItem }>(url, { params });
  }
}
