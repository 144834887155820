<ng-container *tuiLet="breakpointObserver.ltMd$ | async as ltMd">
  <ng-container *tuiLet="breakpointObserver.ltSm$ | async as ltSm">
    @if (newsItem || loading) {
      <div
        class="wrapper flex"
        [class.featured]="mode === 'featured'"
        [class.banner]="mode === 'banner'"
        [class.flex-row]="
          (mode === 'featured' && !ltMd) || (mode === 'banner' && !ltSm)
        "
        [class.flex-column]="
          mode === 'grid-cell' ||
          (mode === 'featured' && ltMd) ||
          (mode === 'banner' && ltSm)
        "
      >
        <div class="flex-100">
          <div class="cover relative" [class.tui-skeleton]="loading">
            @if (newsItem) {
              <cms-video-image-or-carousel
                [media]="newsItem.attributes.cover"
                [aspectRatio]="16 / 9"
                disposition="background"
                controls="simple"
              ></cms-video-image-or-carousel>
            }
          </div>
        </div>
        <div
          class="flex-100 p2"
          [class.pl4]="(mode === 'featured' && !ltMd) "
          [class.pl3]="( mode === 'banner' && !ltSm)"
        >
          <!--    News item     -->
          @if (newsItem && !loading) {
            <cms-news-item-metadata
              [newsItem]="newsItem"
              (click)="stopEventPropagation($event)"
            ></cms-news-item-metadata>
            <h6 class="title tui-text_h6 my2">
              {{ newsItem.attributes.title }}
            </h6>
            <ffb-line-clamp
              [content]="content"
              [linesLimit]="mode === 'featured' ? 9 : 3"
              [lineHeight]="mode === 'featured' ? 24 : 20"
              class="content-wrapper mb2"
            ></ffb-line-clamp>
            <ng-template #content>
              @if (firstParagraph) {
                @if (mode !== 'featured') {
                  <div class="color-text-02" [innerHTML]="firstParagraph"></div>
                } @else {
                  <cms-rich-text
                    class="color-text-02"
                    [content]="firstParagraph"
                  ></cms-rich-text>
                }
              }
            </ng-template>
            <a
              class="color-accent"
              tuiLink
              icon="tuiIconArrowRight"
              iconAlign="left"
            >
              {{ newsItem.attributes.linkLabelToNewsItemDetails }}
            </a>
          }

          <!--    Skeleton     -->
          @if (loading) {
            <p class="tui-skeleton tui-skeleton_short">News item date</p>
            <h6 class="title tui-text_h6 my2 tui-skeleton">News item title</h6>
            <div class="tui-skeleton tui-skeleton_text">
              <p class="tui-skeleton">News item content</p>
              <p class="tui-skeleton tui-skeleton_short">News item content</p>
              <p class="tui-skeleton">News item content</p>
              <p class="tui-skeleton">News item content</p>
              <p class="tui-skeleton">News item content</p>
              <p class="tui-skeleton tui-skeleton_short">News item content</p>
            </div>
          }
        </div>
      </div>
    }
  </ng-container>
</ng-container>
