import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TuiButtonModule } from '@taiga-ui/core';
import { ImageComponent } from '@lancelot-frontend/components';
import { IPartner } from '../../types';
import { RichTextComponent } from '../rich-text/rich-text.component';

@Component({
  selector: 'cms-partner-details',
  templateUrl: './partner-details.component.html',
  styleUrls: ['./partner-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ImageComponent, RichTextComponent, TuiButtonModule],
})
export class PartnerDetailsComponent {
  @Input() partner: IPartner | undefined;
}
