import { AsyncPipe, NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import { TuiButtonModule, TuiLinkModule, TuiModeModule } from '@taiga-ui/core';
import { ImageComponent } from '@lancelot-frontend/components';
import { BreakpointObserver } from '@lancelot-frontend/core';
import { IArticle } from '../../types';
import { RichTextComponent } from '../rich-text/rich-text.component';

@Component({
  selector: 'cms-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    ImageComponent,
    TuiModeModule,
    TuiButtonModule,
    RichTextComponent,
    TuiLinkModule,
    AsyncPipe,
  ],
})
export class ArticleComponent {
  readonly breakpointObserver = inject(BreakpointObserver);

  @Input() article: IArticle | undefined;
}
