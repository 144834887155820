import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  inject,
} from '@angular/core';
import { CalameoEmbedComponent } from '@lancelot-frontend/components';
import { AsDeTreflesService } from '../../services';
import { IAsDeTrefle } from '../../types';

@Component({
  selector: 'cms-as-de-trefle',
  templateUrl: './as-de-trefle.component.html',
  styleUrls: ['./as-de-trefle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CalameoEmbedComponent],
})
export class AsDeTrefleComponent implements OnChanges {
  private asDeTreflesService = inject(AsDeTreflesService);

  @Input() asDeTrefle: IAsDeTrefle | undefined;

  title?: string;

  ngOnChanges() {
    if (this.asDeTrefle) {
      this.title = this.asDeTreflesService.getAsDeTrefleTitle(this.asDeTrefle);
    }
  }
}
