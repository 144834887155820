import { AsyncPipe, NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  inject,
} from '@angular/core';
import { Params, RouterLink } from '@angular/router';
import { TuiButtonModule } from '@taiga-ui/core';
import { SwiperModule } from 'swiper/angular';
import { BreakpointObserver } from '@lancelot-frontend/core';
import { getMockedNewsItems } from '../../mocks';
import { INewsCategory, INewsItem, INewsSection } from '../../types';
import { NewsListItemComponent } from '../news-list-item/news-list-item.component';

@Component({
  selector: 'cms-news-section',
  templateUrl: './news-section.component.html',
  styleUrls: ['./news-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SwiperModule,
    RouterLink,
    NewsListItemComponent,
    TuiButtonModule,
    NgClass,
    AsyncPipe,
  ],
})
export class NewsSectionComponent implements OnInit, OnChanges {
  readonly breakpointObserver = inject(BreakpointObserver);

  @Input() data: Partial<INewsSection> = {};
  @Input() linkToAllNews = true;
  @Input() displayTitle = true;
  @Input() loading = false;

  title?: string;
  newsItems?: INewsItem[];
  category?: INewsCategory | null;
  linkLabelToAllNews?: string;
  linkToAllNewsQueryParams?: Params;

  swiperConfig = {
    breakpoints: {
      // when window width is >= 0px
      0: {
        slidesPerView: 1.2,
        spaceBetween: 16,
        centeredSlides: true,
      },
      // when window width is >= 600px
      600: {
        slidesPerView: 2.2,
        spaceBetween: 16,
        centeredSlides: false,
      },
      // when window width is >= 960px
      960: {
        slidesPerView: 3.2,
        spaceBetween: 16,
        centeredSlides: false,
      },
      1280: {
        slidesPerView: 4,
        spaceBetween: 16,
        centeredSlides: false,
      },
    },
  };

  ngOnInit() {
    this.setData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.data && !changes.data.firstChange) || changes.loading) {
      this.setData();
    }
  }

  setData() {
    this.title = this.data.title;
    this.newsItems =
      this.data.news_items?.data || (this.loading ? getMockedNewsItems(4) : []);
    this.category = this.data.news_category?.data;
    this.linkLabelToAllNews = this.data.linkLabelToAllNews;
    this.linkToAllNewsQueryParams = {};
    if (this.category) {
      this.linkToAllNewsQueryParams.category = this.category.attributes.slug;
      if (this.category.attributes.locale) {
        this.linkToAllNewsQueryParams.locale = this.category.attributes.locale;
      }
    }
  }
}
