@if (data) {
  <div *ffbContentAdornment="1; backgroundColor: 'dark'">
    <div class="featured-content-wrapper">
      <div
        class="featured-content flex flex-column items-stretch content-stretch"
        [ngClass]="{
          'justify-between': (breakpointObserver.ltMd$ | async) === true,
          'justify-center': (breakpointObserver.ltMd$ | async) === false
        }"
        >
        <div class="media-wrapper">
          <div class="media-container">
            @if (data.media) {
              <cms-video-image-or-carousel
                [media]="data.media"
                [autoplay]="data.media.autoplay !== false"
                [aspectRatio]="16 / 9"
                disposition="background"
                controls="simple"
              ></cms-video-image-or-carousel>
            }
          </div>
        </div>
        <ffb-row [yPadding]="false">
          <div
            class="text"
            [ngClass]="{ py4: (breakpointObserver.ltMd$ | async) === true }"
            tuiMode="onDark"
            >
            @if (data.title) {
              <h1
                class="tui-text_h1 mb3"
              [ngClass]="{
                'tui-text_h2': (breakpointObserver.ltLg$ | async) === true
              }"
                >
                {{ data.title }}
              </h1>
            }
            @if (data.abstract) {
              <h4
                class="tui-text_h4 mb2"
              [ngClass]="{
                'tui-text_h5': (breakpointObserver.ltLg$ | async) === true
              }"
                >
                {{ data.abstract }}
              </h4>
            }
            @if (data.callToAction) {
              <a
                tuiButton
                icon="tuiIconArrowRight"
                [href]="data.callToAction.link"
                target="_blank"
                >
                {{ data.callToAction.label }}
              </a>
            }
          </div>
        </ffb-row>
      </div>
    </div>
  </div>
}
