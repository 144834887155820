import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { map, shareReplay } from 'rxjs/operators';
import { EnvironmentService } from '@lancelot-frontend/environment';
import { IPracticalInformation } from '../types';

@Injectable({
  providedIn: 'root',
})
export class PracticalInformationsService {
  private http = inject(HttpClient);

  practicalInformationsUrl =
    inject(EnvironmentService).get('cmsBaseUrl') +
    '/api/practical-informations';
  practicalInformations$ = this._getPracticalInformations();

  private _getPracticalInformations() {
    const params = new HttpParams({
      fromObject: { populate: '*.*' },
    });
    return this.http
      .get<{ data: IPracticalInformation[] }>(this.practicalInformationsUrl, {
        params,
      })
      .pipe(shareReplay({ bufferSize: 1, refCount: false }));
  }

  getPracticalInformations() {
    return this.practicalInformations$;
  }

  getPracticalInformation(slug: string) {
    return this.practicalInformations$.pipe(
      map(({ data: practicalInformations }) => {
        const practicalInformation = practicalInformations.find(
          (c) => c.attributes.slug === slug,
        );
        if (!practicalInformation) {
          throw new HttpErrorResponse({ status: 404 });
        }
        return { data: practicalInformation };
      }),
    );
  }
}
