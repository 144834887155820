@if (article) {
  <div
    class="article flex"
    [ngClass]="{
      'flex-row': (breakpointObserver.ltMd$ | async) === false,
      'flex-column': (breakpointObserver.ltMd$ | async) === true
    }"
    >
    <div class="thumbnail-and-main-call-to-action">
      <div class="thumbnail-wrapper">
        <ffb-image
          [url]="article.attributes.thumbnail.data.attributes.url"
          [aspectRatio]="1 / 1"
          disposition="background"
          backgroundClass="thumbnail mb2 border-radius-l"
          >
        </ffb-image>
      </div>
      @if (article.attributes.mainCallToAction) {
        <div
          class="main-call-to-action p2 mb2 border-radius-l"
          tuiMode="onDark"
          >
          @if (article.attributes.mainCallToAction.title) {
            <h5
              class="tui-text_h5 mb2"
              >
              {{ article.attributes.mainCallToAction.title }}
            </h5>
          }
          <a
            tuiButton
            target="_blank"
            appearance="accent"
            [href]="article.attributes.mainCallToAction.link"
            >
            {{ article.attributes.mainCallToAction.label }}
          </a>
        </div>
      }
    </div>
    <div
      class="content"
      [ngClass]="{ pl3: (breakpointObserver.gtSm$ | async) === true }"
      >
      <h3 class="title tui-text_h3 mb2">{{ article.attributes.title }}</h3>
      @if (article.attributes.intro) {
        <h6
          class="intro tui-text_h6 mb2 color-primary"
          >
          {{ article.attributes.intro }}
        </h6>
      }
      <cms-rich-text [content]="article.attributes.content"></cms-rich-text>
      @for (callToAction of article.attributes.secondaryCallToAction; track callToAction.id) {
        <div
          class="secondary-call-to-action flex justify-between items-center p2 mt2"
        [ngClass]="{
          'flex-row': (breakpointObserver.ltSm$ | async) === false,
          'flex-column': (breakpointObserver.ltSm$ | async) === true
        }"
          >
          <h6 class="tui-text_h6">{{ callToAction.title }}</h6>
          <a
            tuiLink
            class="color-accent"
            icon="tuiIconArrowRight"
            iconAlign="left"
            target="_blank"
            [href]="callToAction.link"
            >
            {{ callToAction.label }}
          </a>
        </div>
      }
    </div>
  </div>
}
