import { loremIpsum } from 'lorem-ipsum';
import { alea as seedRandom } from 'seedrandom';
import { IArticle } from '../types';
import { getMockedArticleCategories } from './article-category.mock';
import {
  getMockedCallToAction,
  getMockedCallsToAction,
} from './call-to-action.mock';
import { contentType } from './content-type.mock';
import { getMockedFile } from './file.mock';

export const getMockedArticles = (n = 3): IArticle[] => {
  const articles = [];

  do {
    const id = (articles.length + 1) as number;
    articles.push({
      id,
      attributes: {
        slug: `article_${id}`,
        title: `Title of article ${id}`,
        intro: loremIpsum({
          count: 1,
          units: 'paragraphs',
          random: seedRandom(id.toString()),
        }),
        content: loremIpsum({
          count: 3,
          units: 'paragraphs',
          random: seedRandom(id.toString()),
        }),
        thumbnail: { data: getMockedFile() },
        mainCallToAction: getMockedCallToAction(),
        secondaryCallToAction: getMockedCallsToAction(),
        article_categories: getMockedArticleCategories(1, { nested: true }),
        linkLabelToArticleDetails: 'Read more',
        ...contentType,
      },
    });
  } while (articles.length < n);

  return articles;
};

export const getMockedArticle = () => getMockedArticles(1)[0];
