import { loremIpsum } from 'lorem-ipsum';
import { alea as seedRandom } from 'seedrandom';
import { IFeaturedContent } from '../types';
import { contentType } from './content-type.mock';
import { getMockedFile } from './file.mock';

export const getMockedFeaturedContent = (): IFeaturedContent => ({
  id: 1,
  title: `Title of featured-content`,
  abstract: loremIpsum({
    count: 1,
    units: 'paragraphs',
    random: seedRandom('featured-content'),
  }),
  callToAction: {
    id: 1,
    label: 'Call to action',
    link: '',
  },
  media: {
    id: 1,
    image: { data: getMockedFile() },
    video: { data: null },
    carousel: { data: null },
  },
  ...contentType,
});
