import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslocoDirective } from '@ngneat/transloco';
import { TranslocoDatePipe } from '@ngneat/transloco-locale';
import { TuiBadgeModule } from '@taiga-ui/kit';
import { differenceInDays, parseISO } from 'date-fns';
import { INewsItem } from '../../types';

@Component({
  selector: 'cms-news-item-metadata',
  templateUrl: './news-item-metadata.component.html',
  styleUrls: ['./news-item-metadata.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoDirective, TuiBadgeModule, RouterLink, TranslocoDatePipe],
})
export class NewsItemMetadataComponent implements OnChanges {
  @Input() newsItem!: INewsItem;
  @Input() mode: 'details' | 'list-item' = 'list-item';

  shouldDisplayUpdateDate?: boolean;

  ngOnChanges() {
    this.shouldDisplayUpdateDate = !!(
      this.mode === 'details' &&
      this.newsItem.attributes.publishedAt &&
      differenceInDays(
        parseISO(this.newsItem.attributes.updatedAt),
        parseISO(this.newsItem.attributes.publishedAt),
      ) >= 1
    );
  }
}
