import { loremIpsum } from 'lorem-ipsum';
import { alea as seedRandom } from 'seedrandom';
import { IAsDeTrefle } from '../types';

export const getMockedAsDeTrefles = (n = 3): IAsDeTrefle[] => {
  const asDeTrefles = [];

  do {
    const number = (asDeTrefles.length + 1) as number;
    asDeTrefles.push({
      ID: number.toString(),
      SubscriptionID: 4540112,
      AccountID: 4516825,
      Name: `As de Trèfle N°${number}`,
      Description: loremIpsum({
        count: 1,
        units: 'paragraphs',
        random: seedRandom(number.toString()),
      }),
      Category: 'MISC',
      Format: 'MISC',
      Dialect: 'fr',
      Status: 'DONE',
      IsPublished: 1 as const,
      IsPrivate: 0 as const,
      AuthID: 'LTDf9GwGH9bl',
      AllowMini: 1 as const,
      Date: new Date().toISOString(),
      Pages: 12,
      Width: 595,
      Height: 841,
      Views: 0,
      Downloads: 0,
      Comments: 0,
      Favorites: 0,
      PosterUrl:
        'https://i.calameoassets.com/151016113106-69d5960ce53f69b75910e802001e6adf/large.jpg',
      PictureUrl:
        'https://i.calameoassets.com/151016113106-69d5960ce53f69b75910e802001e6adf/thumb.jpg',
      ThumbUrl:
        'https://i.calameoassets.com/151016113106-69d5960ce53f69b75910e802001e6adf/p1.jpg',
      PublicUrl:
        'https://www.calameo.com/books/0045168254f0132f6cf5f?authid=LTDf9GwGH9bl',
      ViewUrl:
        'https://www.calameo.com/read/0045168254f0132f6cf5f?authid=LTDf9GwGH9bl',
      Creation: new Date().toISOString(),
      Modification: new Date().toISOString(),
    });
  } while (asDeTrefles.length < n);

  return asDeTrefles;
};

export const getMockedAsDeTrefle = () => getMockedAsDeTrefles(1)[0];
