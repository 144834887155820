import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { map, shareReplay } from 'rxjs/operators';
import { EnvironmentService } from '@lancelot-frontend/environment';
import { IArticleCategory } from '../types';

@Injectable({
  providedIn: 'root',
})
export class ArticlesService {
  private http = inject(HttpClient);

  articleCategoriesUrl =
    inject(EnvironmentService).get('cmsBaseUrl') + '/api/article-categories';
  articleCategories$ = this._getArticleCategories();

  private _getArticleCategories() {
    const params = new HttpParams({
      fromObject: {
        populate: '*.*',
      },
    });
    return this.http
      .get<{ data: IArticleCategory[] }>(this.articleCategoriesUrl, {
        params,
      })
      .pipe(shareReplay({ bufferSize: 1, refCount: false }));
  }

  getArticleCategories() {
    return this.articleCategories$;
  }

  getArticleCategory(slug: string) {
    return this.articleCategories$.pipe(
      map(({ data: articleCategories }) => {
        const articleCategory = articleCategories.find(
          (c) => c.attributes.slug === slug,
        );
        if (!articleCategory) {
          throw new HttpErrorResponse({ status: 404 });
        }
        return { data: articleCategory };
      }),
    );
  }

  getArticle(articleCategorySlug: string, articleSlug: string) {
    return this.getArticleCategory(articleCategorySlug).pipe(
      map(
        ({
          data: {
            attributes: {
              articles: { data: articles },
            },
          },
        }) => {
          const article = articles.find(
            (a) => a.attributes.slug === articleSlug,
          );
          if (!article) {
            throw new HttpErrorResponse({ status: 404 });
          }

          return { data: article };
        },
      ),
    );
  }
}
