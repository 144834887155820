import { loremIpsum } from 'lorem-ipsum';
import { alea as seedRandom } from 'seedrandom';
import { ILeMondeBridgeProblemSolution } from '../types';
import { contentType } from './content-type.mock';

export const getMockedLeMondeBridgeProblemSolutions = (
  n = 3,
): ILeMondeBridgeProblemSolution[] => {
  const leMondeBridgeProblemSolutions = [];

  do {
    const id = (leMondeBridgeProblemSolutions.length + 1) as number;
    leMondeBridgeProblemSolutions.push({
      id,
      attributes: {
        title: 'Board of',
        number: id,
        date: new Date(),
        content: loremIpsum({
          count: 1,
          units: 'paragraphs',
          random: seedRandom(id.toString()),
        }),
        ...contentType,
      },
    });
  } while (leMondeBridgeProblemSolutions.length < n);

  return leMondeBridgeProblemSolutions;
};

export const getMockedLeMondeBridgeProblemSolution = () =>
  getMockedLeMondeBridgeProblemSolutions(1)[0];
