import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { shareReplay } from 'rxjs/operators';
import { EnvironmentService } from '@lancelot-frontend/environment';
import { IMenu } from '../types';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  private http = inject(HttpClient);

  menuUrl = inject(EnvironmentService).get('cmsBaseUrl') + '/api/menu';
  menu$ = this._getMenu();

  private _getMenu() {
    const params = new HttpParams({ fromObject: { populate: '*.*' } });
    return this.http
      .get<{ data: IMenu }>(this.menuUrl, { params })
      .pipe(shareReplay({ bufferSize: 1, refCount: false }));
  }

  getMenu() {
    return this.menu$;
  }
}
