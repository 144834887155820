@if (asDeTrefles) {
  <div
    class="flex"
  [ngClass]="{
    'flex-row': (breakpointObserver.ltSm$ | async) === false,
    'flex-column': (breakpointObserver.ltSm$ | async) === true
  }"
    >
    <div class="slides-and-nav-wrapper">
      <div class="slides-and-nav">
        <swiper
          #swiper
          centeredSlides="true"
          (slideChange)="onSlideChange($event[0])"
          >
          @for (asDeTrefle of asDeTrefles; track asDeTrefle.ID) {
            <ng-template swiperSlide>
              <div class="slide center">
                <a [routerLink]="['as-de-trefle', asDeTrefle.ID]">
                  <ffb-image
                    imageClass="border-radius-xs mx-auto"
                    [url]="asDeTrefle.PosterUrl"
                  ></ffb-image>
                </a>
              </div>
            </ng-template>
          }
        </swiper>
        <div class="flex flex-row justify-center gap2 pt3 pb2">
          <button
            tuiIconButton
            icon="tuiIconArrowLeft"
            appearance="secondary"
            shape="rounded"
            (click)="slidePrev()"
            [disabled]="activeIndex === 0"
          ></button>
          <button
            tuiIconButton
            icon="tuiIconArrowRight"
            appearance="secondary"
            shape="rounded"
            (click)="slideNext()"
            [disabled]="activeIndex === asDeTrefles.length - 1"
          ></button>
        </div>
      </div>
    </div>
    <div class="about">
      <h4 class="tui-text_h4 mb2">{{ title }}</h4>
      <h5 class="tui-text_h5 mb3">{{ subtitle }}</h5>
      <p class="color-primary mb2">
        <tui-svg src="tuiIconCalendar" class="calendar-icon"></tui-svg>
        <span class="tui-text_h4">
          {{ asDeTrefles[activeIndex].Name }}
        </span>
        <span class="tui-text_body-l-2 bold">
          -
          {{
          asDeTrefles[activeIndex].Date
          | translocoDate: { month: 'long', year: 'numeric' }
          }}
        </span>
      </p>
      <p>{{ asDeTrefles[activeIndex].Description }}</p>
      <a
        tuiButton
        icon="tuiIconArrowRight"
        class="mt3"
        [ngClass]="{ 'full-width': (breakpointObserver.ltSm$ | async) === true }"
        [routerLink]="['as-de-trefle', asDeTrefles[activeIndex].ID]"
        >
        {{ linkLabelToPDF }}
      </a>
      <p
        class="mt3"
        [ngClass]="{ center: (breakpointObserver.ltSm$ | async) === true }"
        >
        <a
          tuiLink
          size="s"
          icon="tuiIconArrowRight"
          iconAlign="left"
          routerLink="as-de-trefle"
          >
          {{ linkLabelToAllPDFs }}
        </a>
      </p>
    </div>
  </div>
}
