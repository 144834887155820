@if (partner) {
  <ffb-action>
    <div class="flex flex-row items-center full-width full-height">
      <ffb-image
        [url]="partner.attributes.logo.data.attributes.url"
      [alternativeText]="
        partner.attributes.logo.data.attributes.alternativeText
      "
        [width]="partner.attributes.logo.data.attributes.width"
        [height]="partner.attributes.logo.data.attributes.height"
        imageClass="partner-logo"
      ></ffb-image>
      <h6 class="partner-name tui-text_h6 flex-auto ml2">
        {{ partner.attributes.name }}
      </h6>
    </div>
  </ffb-action>
}
