import { AsyncPipe, NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslocoDatePipe } from '@ngneat/transloco-locale';
import { TuiButtonModule, TuiLinkModule, TuiSvgModule } from '@taiga-ui/core';
import { SwiperComponent, SwiperModule } from 'swiper/angular';
import { SwiperEvents } from 'swiper/types';
import { ImageComponent } from '@lancelot-frontend/components';
import { BreakpointObserver } from '@lancelot-frontend/core';
import { IAsDeTrefle, IAsDeTrefleSection } from '../../types';

@Component({
  selector: 'cms-as-de-trefle-section',
  templateUrl: './as-de-trefle-section.component.html',
  styleUrls: ['./as-de-trefle-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    SwiperModule,
    RouterLink,
    ImageComponent,
    TuiButtonModule,
    TuiSvgModule,
    TuiLinkModule,
    AsyncPipe,
    TranslocoDatePipe,
  ],
})
export class AsDeTrefleSectionComponent implements OnInit {
  private changeDetector = inject(ChangeDetectorRef);
  readonly breakpointObserver = inject(BreakpointObserver);

  @Input() data!: IAsDeTrefleSection;

  asDeTrefles?: IAsDeTrefle[];
  title?: string;
  subtitle?: string;
  linkLabelToPDF?: string;
  linkLabelToAllPDFs?: string;

  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

  activeIndex = 0;

  ngOnInit() {
    this.asDeTrefles = this.data.as_de_trefles.data;
    this.title = this.data.title;
    this.subtitle = this.data.subtitle;
    this.linkLabelToPDF = this.data.linkLabelToPDF;
    this.linkLabelToAllPDFs = this.data.linkLabelToAllPDFs;
  }

  slideNext() {
    this.swiper?.swiperRef.slideNext();
  }

  slidePrev() {
    this.swiper?.swiperRef.slidePrev();
  }

  onSlideChange: SwiperEvents['slideChange'] = ({ activeIndex }) => {
    this.activeIndex = activeIndex;
    this.changeDetector.detectChanges();
  };
}
