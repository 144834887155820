<!--  Vidéo  -->
@if (video) {
  <ffb-video
    [url]="video.url"
    [mime]="video.mime"
    [autoplay]="autoplay === false ? false : true"
    [disposition]="disposition"
    [loop]="disposition === 'background'"
    [controls]="controls"
  ></ffb-video>
}

<!--  Image  -->
@if (image) {
  <ffb-image
    [url]="image.url"
    [alternativeText]="image.alternativeText"
    [width]="image.width"
    [height]="image.height"
    [aspectRatio]="aspectRatio"
    [disposition]="disposition"
    backgroundClass="absolute top-0 right-0 bottom-0 left-0"
  ></ffb-image>
}

<!--  Carousel  -->
@if (carousel) {
  <ffb-carousel
    [slides]="carousel"
    [autoplay]="autoplay === true ? true : false"
    [controls]="{ playPause: true, navigation: disposition !== 'background' }"
    [disposition]="disposition"
    [aspectRatio]="aspectRatio"
  ></ffb-carousel>
}

<!--  Caption  -->
@if (caption && disposition !== 'background') {
  <ffb-caption>
    {{ caption }}
  </ffb-caption>
}
