import { AsyncPipe, NgClass, NgStyle, isPlatformServer } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  PLATFORM_ID,
  inject,
} from '@angular/core';
import { Platform } from '@ionic/angular';
import { TuiButtonModule, TuiModeModule } from '@taiga-ui/core';
import { RowComponent } from '@lancelot-frontend/components';
import { BreakpointObserver, ShowHideDirective } from '@lancelot-frontend/core';
import { ICallsToActionSection } from '../../types';
import { VideoImageOrCarouselComponent } from '../video-image-or-carousel/video-image-or-carousel.component';

@Component({
  selector: 'cms-calls-to-action-section',
  templateUrl: './calls-to-action-section.component.html',
  styleUrls: ['./calls-to-action-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    TuiModeModule,
    RowComponent,
    VideoImageOrCarouselComponent,
    NgStyle,
    TuiButtonModule,
    AsyncPipe,
    ShowHideDirective,
  ],
})
export class CallsToActionSectionComponent {
  readonly breakpointObserver = inject(BreakpointObserver);

  @Input() data!: ICallsToActionSection;

  hoveredItemIndex?: number;
  isTouchDevice =
    !isPlatformServer(inject(PLATFORM_ID)) && !inject(Platform).is('desktop');
}
