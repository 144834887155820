import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { stringify } from 'qs';
import { EnvironmentService } from '@lancelot-frontend/environment';
import { ILeMondeBridgeProblemSolution, IPagination } from '../types';

@Injectable({
  providedIn: 'root',
})
export class LeMondeBridgeProblemSolutionsService {
  private http = inject(HttpClient);

  leMondeBridgeProblemSolutionsUrl =
    inject(EnvironmentService).get('cmsBaseUrl') +
    '/api/le-monde-bridge-problem-solutions';

  getLeMondeBridgeProblemSolution(number: number) {
    const url = `${this.leMondeBridgeProblemSolutionsUrl}/${number}`;
    const params = new HttpParams({ fromObject: { populate: '*.*' } });
    return this.http.get<{ data: ILeMondeBridgeProblemSolution }>(url, {
      params,
    });
  }

  getLeMondeBridgeProblemSolutions(params: Record<string, unknown> = {}) {
    const httpParams = new HttpParams({
      fromString: stringify({
        populate: '*.*',
        ...params,
      }),
    });

    return this.http.get<{
      data: ILeMondeBridgeProblemSolution[];
      meta: IPagination;
    }>(this.leMondeBridgeProblemSolutionsUrl, {
      params: httpParams,
    });
  }
}
