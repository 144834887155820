import { IFile } from '../types';

export const getMockedFile = (
  type: 'image' | 'pdf' | 'video' = 'image',
): IFile => {
  if (type === 'pdf') {
    return {
      id: 1,
      attributes: {
        name: 'pdf_placeholder.pdf',
        hash: 'pdf_placeholder_0ec0c39eaa',
        ext: '.pdf',
        mime: 'application/pdf',
        size: 44.77,
        url: 'https://res.cloudinary.com/ffbridge/image/upload/v1652100841/dev-recette-robot/cms/pdf_placeholder_0ec0c39eaa.pdf?updated_at=2022-05-09T11:08:49.354Z',
        provider: 'cloudinary',
        provider_metadata: {
          public_id: 'dev-recette-robot/cms/pdf_placeholder_0ec0c39eaa',
          resource_type: 'image',
        },
      },
    };
  }

  return {
    id: 1,
    attributes: {
      name: 'image_placeholder.png',
      alternativeText: 'image_placeholder.png',
      caption: 'image_placeholder.png',
      width: 800,
      height: 450,
      formats: {
        thumbnail: {
          name: 'thumbnail_image_placeholder.png',
          hash: 'thumbnail_image_placeholder_0c4efb8c60',
          ext: '.png',
          mime: 'image/png',
          width: 245,
          height: 138,
          size: 4.23,
          url: 'https://res.cloudinary.com/ffbridge/image/upload/v1652167365/dev-recette-robot/cms/thumbnail_image_placeholder_0c4efb8c60.png',
          provider_metadata: {
            public_id:
              'dev-recette-robot/cms/thumbnail_image_placeholder_0c4efb8c60',
            resource_type: 'image',
          },
        },
      },
      hash: 'image_placeholder_0c4efb8c60',
      ext: '.png',
      mime: 'image/png',
      size: 7.51,
      url: 'https://res.cloudinary.com/ffbridge/image/upload/v1652167364/dev-recette-robot/cms/image_placeholder_0c4efb8c60.png',
      provider: 'cloudinary',
      provider_metadata: {
        public_id: 'dev-recette-robot/cms/image_placeholder_0c4efb8c60',
        resource_type: 'image',
      },
    },
  };
};
