import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ActionComponent, ImageComponent } from '@lancelot-frontend/components';
import { IPartner } from '../../types';

@Component({
  selector: 'cms-partners-list-item',
  templateUrl: './partners-list-item.component.html',
  styleUrls: ['./partners-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ActionComponent, ImageComponent],
})
export class PartnersListItemComponent {
  @Input() partner: IPartner | undefined;
}
