import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { TranslocoLocaleService } from '@ngneat/transloco-locale';
import { stringify } from 'qs';
import { EnvironmentService } from '@lancelot-frontend/environment';
import { IAsDeTrefle, IPagination } from '../types';

@Injectable({
  providedIn: 'root',
})
export class AsDeTreflesService {
  private http = inject(HttpClient);
  private translocoLocaleService = inject(TranslocoLocaleService);

  asDeTreflesUrl =
    inject(EnvironmentService).get('cmsBaseUrl') + '/api/as-de-trefles';

  getAsDeTrefles(params: Record<string, unknown> = {}) {
    const httpParams = new HttpParams({
      fromString: stringify({
        populate: '*.*',
        ...params,
      }),
    });
    return this.http.get<{ data: IAsDeTrefle[]; meta: IPagination }>(
      this.asDeTreflesUrl,
      {
        params: httpParams,
      },
    );
  }

  getAsDeTrefle(id: string) {
    const url = `${this.asDeTreflesUrl}/${id}`;
    const params = new HttpParams({ fromObject: { populate: '*.*' } });
    return this.http.get<{ data: IAsDeTrefle }>(url, { params });
  }

  getAsDeTrefleTitle(asDeTrefle: IAsDeTrefle) {
    return `${asDeTrefle.Name}, ${this.translocoLocaleService.localizeDate(
      asDeTrefle.Date,
      undefined,
      {
        month: 'long',
        year: 'numeric',
      },
    )}`;
  }
}
