import { AsyncPipe, NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import { TuiButtonModule, TuiModeModule } from '@taiga-ui/core';
import {
  ContentAdornmentDirective,
  RowComponent,
} from '@lancelot-frontend/components';
import { BreakpointObserver } from '@lancelot-frontend/core';
import { IFeaturedContent } from '../../types';
import { VideoImageOrCarouselComponent } from '../video-image-or-carousel/video-image-or-carousel.component';

@Component({
  selector: 'cms-featured-content',
  templateUrl: './featured-content.component.html',
  styleUrls: ['./featured-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ContentAdornmentDirective,
    NgClass,
    VideoImageOrCarouselComponent,
    RowComponent,
    TuiModeModule,
    TuiButtonModule,
    AsyncPipe,
  ],
})
export class FeaturedContentComponent {
  readonly breakpointObserver = inject(BreakpointObserver);

  @Input() data: IFeaturedContent | undefined;
}
