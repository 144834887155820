<ng-container *transloco="let t">
  <div class="flex flex-row">
    @if (newsItem.attributes.publishedAt) {
      <p class="color-text-03">
        {{ newsItem.attributes.publishedAt | translocoDate }}
        @if (shouldDisplayUpdateDate) {
          <span class="italic ml2">
            {{
            t('cms.updatedAt', {
            date: newsItem.attributes.updatedAt | translocoDate
            })
            }}
          </span>
        }
      </p>
    }
    @if (!newsItem.attributes.publishedAt) {
      <tui-badge
        status="warning"
        value="{{ t('cms.draft') }}"
      ></tui-badge>
    }
    <div class="flex flex-auto flex-row flex-wrap justify-end items-end gap1">
      @for (category of newsItem.attributes.news_categories.data; track category.id) {
        <a
          routerLink="/news"
        [queryParams]="{
          category: category.attributes.slug,
          locale: category.attributes.locale
        }"
          >
          <tui-badge
            status="primary"
            [value]="category.attributes.name"
          ></tui-badge>
        </a>
      }
    </div>
  </div>
</ng-container>
