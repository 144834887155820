import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  ContentAdornmentDirective,
  ImageComponent,
  RowComponent,
  VideoComponent,
} from '@lancelot-frontend/components';
import { ShowHideDirective } from '@lancelot-frontend/core';
import { IFile } from '../../types/file.interface';

@Component({
  selector: 'cms-media-grid',
  templateUrl: './media-grid.component.html',
  styleUrls: ['./media-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ContentAdornmentDirective,
    RowComponent,
    NgTemplateOutlet,
    ImageComponent,
    VideoComponent,
    ShowHideDirective,
  ],
})
export class MediaGridComponent {
  @Input({ required: true }) items!: IFile[];
}
