"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _ = require(".");

/**
 * @param length  Length "x".
 * @returns       An array of strings of length "x".
 */
var makeArrayOfStrings = function makeArrayOfStrings(length, makeString) {
  var arr = (0, _.makeArrayOfLength)(length);
  return arr.map(function () {
    return makeString();
  });
};
var _default = makeArrayOfStrings;
exports["default"] = _default;
